import { Alert, AlertTitle, Box } from '@mui/material'
import { styled } from '@mui/styles'
// import { getErrorMessage } from 'common/utils/getErrorMessage'
import { RefreshCw } from 'lucide-react'
// import { useRouteError } from 'react-router-dom'
import Button from './Button'

export const ErrorBoundary = ({ message }) => {
  // let error = useRouteError()

  return (
    <Container>
      <Alert severity="error">
        <AlertTitle sx={{ fontSize: (theme) => theme.typography.h3.fontSize }}>
          Something went wrong
        </AlertTitle>
        {/* {message ? (
          message
        ) : getErrorMessage(error) ? (
          <Typography variant="textPrimary" color="error.main">{`${getErrorMessage(
            error
          )}`}</Typography>
        ) : (
          'An unexpected error occurred. Please try again or refresh the page. If the issue persists, contact support for further assistance.'
        )} */}
        An unexpected error occurred. Please try again or refresh the page. If the issue persists,
        contact support for further assistance.
        <Button
          startIcon={<RefreshCw />}
          size="small"
          sx={{ width: '100%', mt: 1 }}
          color="secondary"
          onClick={() => window.location.reload()}
        >
          Refresh
        </Button>
      </Alert>
    </Container>
  )
}

const Container = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  flexGrow: 1,
  justifyContent: 'center',
  alignItems: 'flex-start',
  overflow: 'auto',
  height: '100%',
  minHeight: 600,
  maxWidth: 800,
  margin: 'auto',
  color: theme.palette.error.main,
}))
